<template>
  <b-card>
    <b-row class="mb-1">
      <b-col
        md="6"
        sm="1"
        xs="11"
      />
      <b-col
        md="6"
        sm="11"
        xs="11"
        style="text-align: right"
      >
        <b-input-group>
          <b-form-input
            id="filterInput"
            v-model="filter"
            type="search"
            placeholder="Digite algo para pesquisar"
          />
          <b-input-group-append>
            <b-button
              size="sm"
              :disabled="!filter"
              @click="filter = ''"
            >
              Limpar
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col>
        <div class="d-flex align-items-center justify-content-end" />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-table
          ref="i2GridTable"
          striped
          hover
          bordered
          sticky-header
          responsive
          :per-page="perPage"
          :current-page="currentPage"
          :items="items"
          :fields="fields"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template
            v-for="(_, slotName) of $scopedSlots"
            v-slot:[slotName]="data"
          >
            <slot
              :name="slotName"
              v-bind="data"
            />
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        md="6"
        sm="6"
        xs="6"
      >
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
        />
      </b-col>
      <b-col
        md="6"
        sm="4"
        xs="4"
      >
        <b-form-group
          class="mb-0"
          style="text-align: right"
        >
          <label class="d-inline-block text-sm-left pr-1">Por página: </label>
          <b-form-select
            id="perPageSelect"
            v-model="perPage"

            :options="pageOptions"
            class="w-25"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </b-card>

</template>

<script>

import {
  BTable, BCard, BPagination, BButton, BInputGroup, BInputGroupAppend, BFormInput, BCol, BRow, BFormGroup, BFormSelect,
} from 'bootstrap-vue'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'Grid',
  components: {
    BTable,
    BCard,
    BPagination,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BCol,
    BRow,
    BFormGroup,
    BFormSelect,
  },
  props: {
    fields: {
      type: [Array],
      required: true,
    },
    items: {
      type: [Array],
      required: true,
    },
    newButtonLabel: {
      type: String,
      default: 'Adicionar novo',
      required: true,
    },
    editButtonLabel: {
      type: String,
      default: 'Adicionar novo',
      required: true,
    },
  },
  data: () => ({
    perPage: 15,
    pageOptions: [3, 5, 10, 15, 30, 50],
    totalRows: 1,
    currentPage: 1,
    filter: null,
    item: {},
    breakPoint: '',
    filterOn: [],
  }),
  computed: {
    ...mapState(['app/windowWidth']),
  },
  created() {
    this.breakPoint = this.currentBreakPoint()
    this.unsubscribe = this.$store.subscribe(mutation => {
      if (mutation.type === 'app/UPDATE_WINDOW_WIDTH') {
        this.breakPoint = this.currentBreakPoint()
      }
    })
    this.totalRows = this.items.length
  },
  updated() {
    this.totalRows = this.items.length
    this.breakPoint = this.currentBreakPoint()
  },
  methods: {
    ...mapGetters('app', ['currentBreakPoint']),
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>
